import { Reducer } from 'redux'

import { IOnboardingState, OnboardingActionTypes } from './types'

export const initialState: IOnboardingState = {
  telegram: [],
  avito: [],
  facebook: [],
  mytarget: [],
  tiktok: [],
  vk: [],
  vkads: [],
  vk_adsmarket: [],
  yandex_business: [],
  yandex_promo_page: [],
  profi: [],
  yandex: [],
}

const reducer: Reducer<IOnboardingState> = (state = initialState, action: any) => {
  switch (action.type) {
    case OnboardingActionTypes.SET_ACCOUNTS_ONBOARDING: {
      return {
        ...action.data,
      }
    }
    default: {
      return state
    }
  }
}

export { reducer as onboardingReducer }
