export enum Routes {
  ADD_CLIENT = '/add-client',
  ANALYTICS = '/analytics',
  ACCOUNTS = '/accounts',
  ADD_ACCOUNTS = '/accounts/add',
  BALANCE = '/balance',
  CHANGE_PASSWORD = '/user/change_password',
  ACCOUNTING = '/profile/accounting',
  INDEX = '/',
  INVOICES = '/invoices',
  HISTORY = '/history',
  LOGOUT = '/logout',
  PROFILE = '/profile',
  SIGNIN = '/user/signin',
  SIGNUP = '/user/signup',
  FORGOT_PASSWORD = '/user/forgot_password',
  FORGOT_PASSWORD_CONFIRM = '/user/forgot_password/:token',
  BUDGET = '/budget',
  SETTINGS = '/settings',
  OPTIMIZER = '/optimizer',
  FEEDMANAGER = '/feedmanager',
  FEEDMANAGER_ABOUT = '/feedmanager/about',
  FEEDMANAGER_WELCOME = '/feedmanager/welcome',
  FEEDMANAGER_CREATE = '/feedmanager/create',
  SHARE_AUDIENCES = '/share-audiences',
  AUDIENCES = '/audiences',
  AUDIENCES_WELCOME = '/audiences/welcome',
  AUDIENCES_QUIZ = '/audiences/quiz',
  ADS_MARKING = '/ads-marking',
  NOTIFICATIONS = '/notifications',
  REFERRAL = '/referral',
  REFERRAL_WELCOME = '/referral/welcome',
  MY_CASHBACK = '/my-cashback',
  ANTIBAN = '/antiban',
  ANTIBAN_WELCOME = '/antiban/welcome',
  CAMPAIGN_EDITOR = '/campaign-editor',
  IMPORT_VK = '/import-vk',
  IMPORT_VK_VERIFY = '/import-vk/verify',
  CONFIRM_CLIENT = '/confirm-client',
  TRACKER = '/tracker',
  ADD_AVITO = '/accounts/add/avito',
  ADD_VK_ADS = '/accounts/add/vkads',
  ADD_VK_ADS_MARKET = '/accounts/add/vk_adsmarket',
  REPORTS = '/reports',
  ADD_TELEGRAM = '/accounts/add/telegram',
  ADD_YANDEX_PROMO_PAGE = '/accounts/add/yandex_promo_page',
  ADD_CONTRACT = '/ads-marking/add-contract',
  ACCESS_DENIED = '/access-denied',
}

export enum RouteParams {
  CLIENT_ID = ':clientId?',
  NETWORK = ':network(facebook|vk|mytarget|tiktok|yandex)',
  TAB = ':tab(acts|documents)?',
  ID = ':id',
  BLOCK_ID = ':blockId?',
  ACCOUNT_ID = ':accountId?',
  ANTIBAN_BLOCKING_SECTION = ':section(blocking-actions)',
  ANTIBAN_PROTECTION_SECTION = ':section(increase-protection)',
  ANTIBAN_BLOCKING_SUBSECTION = ':subsection(i-dont-know|business-manager|account|business-page|profile)?',
  ANTIBAN_PROTECTION_SUBSECTION = ':subsection(business-manager|account|business-page|profile)',
  ACCOUNT_TAB = ':tab(facebook|vk|mytarget|tiktok|yandex|archived_accounts|avito|telegram|yandex_promo_page|yandex_business|profi|vkads|vk_adsmarket)?',
  ACCOUNT_SECTION = ':section(platform|client)?',
  ADS_MARKING_TAB = ':tab(accounts|contracts|counterparties)',
  CLIENT_UUID = ':uuid?',
  REPORT_ID = ':id?',
  FILTER_TAB = ':filterTab?',
}

export const AGENCY_CLIENT_ALLOWED_ROUTES = [
  Routes.ANALYTICS,
  Routes.REPORTS,
  Routes.ACCESS_DENIED,
  Routes.PROFILE,
  Routes.INDEX,
  Routes.CHANGE_PASSWORD,
  Routes.CAMPAIGN_EDITOR,
  Routes.ADS_MARKING,
  Routes.ADD_CONTRACT,
]
