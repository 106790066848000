import { SyntheticEvent } from 'react'
import mixpanel from 'mixpanel-browser'

interface IMixpanelProps {
  $email?: string
  Industry?: string
  'Paying Customer'?: 'Yes' | 'No'
  'Client ID'?: number | null
  Optimizer?: 'Yes' | 'No'
  'Feed Manager'?: 'Yes' | 'No'
  'Facebook AdAccounts'?: number
  'myTarget AdAccounts'?: number
  'VK AdAccounts'?: number
  'TikTok AdAccounts'?: number
  'Archived AdAccounts'?: number
  $name?: string
  Audiences?: 'Yes' | 'No'
  'Avito AdAccounts'?: number
  'Yandex AdAccounts'?: number
}

let isMixpanelLoaded = false

if (mixpanel) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN || '', {
    loaded: () => {
      isMixpanelLoaded = true
    },
  })
}

const isProduction = process.env.NODE_ENV === 'production' && process.env.REACT_APP_MIXPANEL_TOKEN

const actions = {
  alias: (id: string) => {
    if (isProduction && isMixpanelLoaded) {
      mixpanel.alias(id.toLowerCase())
    }
  },
  identify: (id: string) => {
    if (isProduction && isMixpanelLoaded) {
      mixpanel.identify(id.toLowerCase())
    }
  },
  reset: () => {
    if (isProduction && isMixpanelLoaded) {
      mixpanel.reset()
    }
  },
  set: (props: IMixpanelProps) => {
    if (isProduction && isMixpanelLoaded) {
      mixpanel.people.set(props)
    }
  },
  track: (name: string, props?: any) => {
    if (isProduction && isMixpanelLoaded) {
      mixpanel.track(name, props)
    }
  },
}

export const sendMixpanelEvent = (eventName: string, props?: { [key: string]: string }) => {
  if (props) {
    actions.track(eventName, props)
  } else {
    actions.track(eventName)
  }
}

export const onSendMixpanelEvent = (eventName: string, props?: { [key: string]: string }) => {
  return (event?: SyntheticEvent) => {
    event?.stopPropagation()

    sendMixpanelEvent(eventName, props)
  }
}

export const Mixpanel = actions
