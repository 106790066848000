export enum FORM_FIELDS {
  CONTRACTOR_LEGAL_STATUS = 'contractor_legal_status',
  OTHER_REGISTRATION_NUMBER = 'other_registration_number',
  OTHER_COUNTRY = 'other_country',
  OTHER_PAYMENT_NUMBER = 'other_payment_number',
  TAX_NUMBER = 'tax_number',
  RU_NAME = 'ru_name',
  PHONE_NUMBER = 'phone_number',
  RU_INN = 'ru_inn',
  RU_KPP = 'ru_kpp',
  RU_CEO = 'ru_ceo',
  RU_LEGAL_ADDRESS = 'ru_legal_address',
  OKVED = 'okved',
  OKVED_CODES = 'okved_codes',
}

export enum COUNTRY_SELECT {
  RUSSIAN = 'russia',
  OTHER = 'other',
}

export enum CONTRACTOR_TYPE {
  EXECUTOR = 'executor',
  ADVERTISER = 'advertiser',
}

export enum NetworkAccountsTypes {
  YANDEX_ACCOUNT = 'yandex_account',
  VKADS_ACCOUNT = 'vkads_account',
  VK_ACCOUNT = 'vk_account',
  MYTARGET_ACCOUNT = 'mytarget_account',
  TELEGRAMADS_ACCOUN = 'telegramads_account',
}

export enum AdsMarkingTabType {
  ACCOUNTS = 'accounts',
  CONTRACTS = 'contracts',
  COUNTERPARTIES = 'counterparties',
}

export const enum MIXPANEL_ADS_MARKING_KEYS {
  MARKING_ACCOUNT_ADD_CONTRACT_MODEST_SAVE = 'MarkingAccountAddcontractmodestSave',
}

export const enum MIXPANEL_ADS_MARKING_PARAMS {
  MODEST = 'modest',
}

export enum CONTRACT_TYPE {
  PROVISION_SERVICES = 'provision_of_services',
  MEDIATION = 'mediation',
  ADDITIONAL_AGREEMENT = 'additional_agreement',
}

export enum CONTRACT_SUBJECT_TYPE {
  REPRESENTATION = 'representation',
  MEDIATION = 'mediation',
  ORG_DISTRIBUTION = 'org_distribution',
  DISTRIBUTION = 'distribution',
  OTHER = 'other',
}

export enum CONTRACT_ACTION_TYPE {
  DISTRIBUTION = 'distribution',
  CONCLUDE = 'conclude',
  COMMERCIAL = 'commercial',
  OTHER = 'other',
}

export enum AddContractTypes {
  SET_CONTRACT_COUNTERPARTY = 'ADS_MARKING_SET_CONTRACT_COUNTERPARTY',
  SET_CONTRACT_COUNTERPARTY_ID = 'ADS_MARKING_SET_CONTRACT_COUNTERPARTY_ID',
  SET_CONTRACT_DETAILS = 'ADS_MARKING_SET_CONTRACT_DETAILS',
  SET_CONTRACT_DETAILS_ID = 'ADS_MARKING_SET_CONTRACT_DETAILS_ID',
  SET_CONTRACT_EXECUTOR = 'ADS_MARKING_SET_CONTRACT_EXECUTOR',
  SET_CONTRACT_EXECUTOR_ID = 'ADS_MARKING_SET_CONTRACT_EXECUTOR_ID',
  SET_CONTRACT_ADVERTISER = 'ADS_MARKING_SET_CONTRACT_ADVERTISER',
  SET_CONTRACT_ADVERTISER_ID = 'ADS_MARKING_SET_CONTRACT_ADVERTISER_ID',
  SET_MY_AGGENCY = 'ADS_MARKING_SET_MY_AGGENCY',
  SET_CONTRACT_ACCOUNTS = 'ADS_MARKING_SET_CONTRACT_ACCOUNTS',
  SET_ACCOUNTS_ATTACHED = 'ADS_MARKING_SET_ACCOUNTS_ATTACHED',
  SET_CONTRACT_ID = 'ADS_MARKING_SET_CONTRACT_ID',
  CLEAR_CONTRACT = 'ADS_MARKING_CLEAR_CONTRACT',
  SET_LOADING = 'ADS_MARKING_CONTRACT_LOADING',
  SET_CREATE_LOADING = 'ADS_MARKING_SET_CREATE_LOADING',
  SET_NEW_MARKING_DETAILS = 'ADS_MARKING_SET_NEW_MARKING_DETAILS',
}
