import {
  Currencies,
  Industries,
  LinkNetworkTypes,
  NetworkExtraTypes,
  Percentage,
  SpaceDelimiter,
  YandexPercentage,
} from '@/types'

export const API_URL = process.env.NODE_ENV === 'development' ? 'https://smb-api-master.onespot.one/api' : '/api'

export const URL_REGEX =
  '^(http://www.|https://www.|http://|https://)?[-a-zA-Z0-9а-яА-Я]+([-.]{1}[-a-zA-Z0-9а-яА-Я]+)*[.][-a-zA-Zа-яА-Я]{2,15}(:[0-9]{1,15})?(/.*)?$'

export const START_URL_REGEX = /https?:?\/?\/?(www\.)?/

export const PHONE_NUMBER_REGEX = '^\\+7 \\([0-9]{3}\\) [0-9]{3}-[0-9]{2}-[0-9]{2}$'
export const PHONE_NUMBER_FIX = '+79999999999'

export const INSTAGRAM_REGEX = '^[a-zA-Z0-9-_@./:%]+$'

export const YANDEX_LOGIN_REGEX = '^[^0-9]+[a-zA-Z0-9-]+$'
export const YANDEX_LOGIN_REQUEST_REGEX = /@.*$/
export const NUM_PATTERN_2_DEC_REGEX = /^(?:\d{1,8}|\d{0,8}\.\d{1,2})$/
export const INN_REGEX = '^\\d{12}$'

export const AUTH_PASSWORD_PATTERN_REGEX = /^(?=.*[A-Za-z]).{8,}$/
export const DIGIT_REGEX = /\d/g
export const NON_DIGIT_REGEX = /\D/g
export const AUTH_PASSWORD_MIN_LENGTH = 8

export const CurrencyReg = new RegExp('^[0-9]+([.,])?([0-9]{1,2})?$')

export const CurrencyWithoutFloatReg = new RegExp('^[0-9]+$')
export const REGULAR_EXPRESSION_CYRILLIC_NAME = new RegExp(/^[А-ЯЁа-яё-]+( [А-ЯЁа-яё-]+)+$/)

export const CurrenciesRegex = /[ ₽€]/gi

export const MAX_INN_LENGTH = 12

export enum DADATA_INN_TYPES {
  LEGAL = 'LEGAL',
  INDIVIDUAL = 'INDIVIDUAL',
  PHYSICAL = 'PHYSICAL',
}

export const SuggestCompanyTypes: any = {
  INDIVIDUAL: 'ip',
  LEGAL: 'llc',
  SE: 'se', // Self employed
  FIZ: 'fiz',
}

export const SuggestCompanyLegalStatusTypes: any = {
  INDIVIDUAL: 'INDIVIDUAL',
  LEGAL: 'LEGAL',
}

export const ProfileSources: { [key: string]: string } = {
  telegram_ads: 'Telegram Ads',
  telegram_post: 'Пост в Telegram-канале',
  other_social_networks: 'Другие соцсети',
  yandex_ads: 'Реклама в Яндексе',
  tg_stat: 'TgStat',
  colleagues: 'Коллеги рассказали',
  browser_search: 'Поиск в браузере',
  blog_onespot: 'Блог OneSpot',
  mass_media: 'СМИ',
  other: 'Другое',
}

export const MailProviders: any = {
  'bk.ru': 'https://e.mail.ru/',
  'gmail.com': 'https://mail.google.com',
  'icloud.com': 'https://www.icloud.com/',
  'inbox.ru': 'https://e.mail.ru/',
  'list.ru': 'https://e.mail.ru/',
  'mail.ru': 'https://e.mail.ru/',
  'outlook.com': 'https://outlook.live.com/mail/',
  'rambler.ru': 'https://mail.rambler.ru',
  'ya.ru': 'https://mail.yandex.ru/',
  'yahoo.com': 'https://mail.yahoo.com',
  'yandex.com': 'https://mail.yandex.com/',
  'yandex.kz': 'https://mail.yandex.kz/',
  'yandex.ru': 'https://mail.yandex.ru/',
}

export const InvoicesStatusesText: any = {
  issued: 'Ожидает оплаты',
  partially_payed: 'Оплачен частично',
  partially_processed: 'Оплачен',
  payed: 'Оплачен',
  processed: 'Оплачен',
}

export const NetworkNames: { [key in NetworkExtraTypes]: string } = {
  facebook: 'Facebook',
  mytarget: 'myTarget',
  tiktok: 'TikTok',
  vk: 'Вконтакте',
  yandex: 'Яндекс Директ',
  avito: 'Авито',
  telegram: 'Telegram Ads',
  yandex_promo_page: 'Яндекс ПромоСтраницы',
  vkads: 'VK Реклама',
  vk_adsmarket: 'Маркет-платформа ВКонтакте',
}

export const LinkNetworkNames: { [key in LinkNetworkTypes]: string } = {
  two_gis: '2ГИС',
}

export const enum ContractType {
  CONTRACT_POST = 'contract_post',
  OFFER = 'offer',
}

export const AGENCY = 'Агентство'

export const YandexBenefitsPercentage: YandexPercentage = {
  MORE_0: Percentage.FOUR,
  MORE_30_000: Percentage.FOUR,
  MORE_100_000: Percentage.FIVE,
  MORE_200_000: Percentage.FIVE,
  MORE_1_000_000: Percentage.SIX,
  MORE_3_000_000: Percentage.SIX,
}

export const SPACE: SpaceDelimiter = [' ', ',']

export const Delimiters = {
  SPACE,
}

export const CurrencyNodes: { [key in Currencies]: string } = {
  RUB: '₽',
  EUR: '€',
}

export const LINK_CASHBACK_PERSONAL = 'https://files.onespot.one/smb/cashback_personal.pdf'

export const enum DATE_FORMAT {
  YYYY_MM_DD = 'YYYY-MM-DD',
}

export const MTS_EXCHANGE_RATE = 165

export const TELEGRAM_MIN_EUR_COUNT = 1500

export const TELEGRAM_EXTRA_EXCHANGE_RATE_INDUSTRIES = [
  Industries.OTHERS,
  Industries.EDUCATION,
  Industries.CRYPTOCURRENCIES,
  Industries.MTS_PRO_BIG_DATA,
]

export const individualMinEurCount = {
  [Industries.OTHERS]: 500,
  [Industries.REAL_ESTATE]: 500,
  [Industries.EDUCATION]: 500,
  [Industries.CRYPTOCURRENCIES]: 500,
  [Industries.MTS_PRO_BIG_DATA]: 250,
}

export const VAT = 0.2

export const DELAY_FOR_MACROTASK_QUEUE = 200

export const enum FORM_ERROR_TYPE {
  MANUAL = 'manual',
  MESSAGE = 'message',
  REQUEST_ERROR = 'request_error',
}

export enum ADS_MARKING_QUERY_PARAMS_FILTER {
  AD_GROUP = 'ad_group',
}

export enum SENTRY_MESSAGE {
  ERROR_AUTHORIZATION = 'Ошибка авторизации',
  ERROR_EXPIRED_TOKEN_DATE = 'Дата токена в прошлом',
  ERROR_EXPIRED_TOKEN_QUERY_LOOP = 'Ошибка добавления клиента (УСН)',
  ERROR_ADD_USER = 'Ошибка регистрации',
  ERROR_USN_ADD_USER = 'Ошибка регистрации (УСН)',
  ERROR_USN_ADD_PROFILE = 'Ошибка создания профиля (УСН)',
  ERROR_USN_ADD_CLIENT = 'Ошибка добавления клиента (УСН)',
  ERROR_LOADING_PREDEFINED_AUDIENCES = 'Проблема с загрузкой в готовых аудиториях',
  ERROR_LOADING_KKTU = 'Проблема с загрузкой категорий рекламируемых товаров или услуг',
}
