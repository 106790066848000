import { Reducer } from 'redux'

import { reducerName } from './modals/actions'
import { AccountsActionTypes, AccountsFormsActionTypes, IAccountsState } from './types'

const initialList = {
  count: 0,
  current: 0,
  next: null,
  page_count: 0,
  previous: null,
  results: [],
  loading: false,
}

export const initialState: IAccountsState = {
  accountsList: initialList,
  accountsSelectedList: initialList,
  accountsFilled: {
    account_filled: 0,
    account_total: 0,
    has_profile_errors: false,
  },
  filterQuery: '',
  modals: null,
  profileAdmarkInfo: {
    has_errors: false,
  },
}

const AdsMarkingAccountsReducer: Reducer<IAccountsState> = (state = initialState, action: any) => {
  switch (action.type) {
    case AccountsActionTypes.SET_ACCOUNTS: {
      const { results, ...listParams } = action.data
      return {
        ...state,
        accountsList: {
          ...listParams,
          loading: false,
          results: [...state.accountsList.results, ...results],
        },
      }
    }
    case AccountsActionTypes.UPDATE_ACCOUNTS: {
      return { ...state, accountsList: { ...action.data, loading: false } }
    }
    case AccountsActionTypes.SET_ACCOUNTS_FILTER_QUERY: {
      return { ...state, filterQuery: action.query }
    }
    case AccountsActionTypes.CLEAR_ACCOUNTS: {
      return {
        ...state,
        accountsList: {
          ...state.accountsList,
          results: [],
        },
      }
    }
    case AccountsActionTypes.SET_ACCOUNTS_FILLED: {
      return { ...state, accountsFilled: action.data }
    }
    case AccountsActionTypes.SET_ACCOUNTS_LOADING: {
      return { ...state, accountsList: { ...state.accountsList, loading: true } }
    }
    case AccountsFormsActionTypes.SET_ACCOUNTS_SELECTED: {
      const { results, ...listParams } = action.data
      return {
        ...state,
        accountsSelectedList: {
          ...listParams,
          loading: false,
          results: [...state.accountsSelectedList.results, ...results],
        },
      }
    }
    case AccountsFormsActionTypes.SET_ACCOUNTS_SELECTED_LOADING: {
      return { ...state, accountsSelectedList: { ...state.accountsSelectedList, loading: true } }
    }
    case AccountsActionTypes.SET_ACCOUNTS_PROFILE_ADMARK_INFO: {
      return {
        ...state,
        profileAdmarkInfo: { ...state.profileAdmarkInfo, ...action.data },
      }
    }
    case `${reducerName}.OPEN_MODAL`: {
      return {
        ...state,
        modals: action.payload,
      }
    }
    case `${reducerName}.CLOSE_MODAL`: {
      return {
        ...state,
        modals: null,
      }
    }
    default: {
      return state
    }
  }
}

export default AdsMarkingAccountsReducer
